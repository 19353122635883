
import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import clsx from 'clsx';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Callout from '../components/Callout/Callout';
import Hero from '../components/Hero/Hero';
import InfoBox from '../components/InfoBox/InfoBox';
import Team from '../components/Team/Team';
import TextSection from '../components/TextSection/TextSection';
import Title from '../components/Title/Title';

export default function TeamPage({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-team')} text={data.page.data.header_text.html} image={data.page.data.header_image} />
        <Breadcrumb className={data.page.data.breadcrumb_css_class && data.page.data.breadcrumb_css_class.text} list={data.page.data.breadcrumb}/>
        <InfoBox data={{ headline: data.page.data.stats_headline, stats: data.page.data.stats, css_class: {text: ''}}} />
        <TextSection className={'border-bottom-gray'} text={[data.page.data.mission.html]}/>
        <Title className="add-spacing add-container text-black">{parse(data.page.data.team_headline.html)}</Title>
        <Team className={'delix-team'} headline={data.page.data.leadership_headline && data.page.data.leadership_headline.html} team={data.page.data.leadership} />
        <Team className={'delix-team'} headline={data.page.data.directors_headline && data.page.data.directors_headline.html} team={data.page.data.directors} />
        <Team className={'delix-team'} headline={data.page.data.advisory_headline && data.page.data.advisory_headline.html} team={data.page.data.advisory} />
        <Team className={'delix-team'} headline={data.page.data.employees_headline && data.page.data.employees_headline.html} team={data.page.data.employees} />
        <Callout
          text={ data.page.data.callout_text.html }
          cta={{
            text: data.page.data.callout_cta_text.text,
            url: data.page.data.callout_cta_url
          }}
          image={ data.page.data.callout_image }
        />
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const LeadershipQuery = graphql`
query {
  page: prismicLeadership {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      }
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
      callout_text {
        html
      }
      callout_cta_text {
        text
      }
      callout_cta_url {
        target
        slug
        link_type
        url
      }
      callout_image {
        alt
        url
      }
  	  stats_headline {
        html
      }
      stats {
        text {
          html
        }
        css_class {
          text
        }
        image {
          url
          alt
        }
      }
      mission {
        html
      }
      team_headline {
        html
      }
      leadership_headline {
      	html
      }
      leadership {
        name {
          text
        }
        position {
          text
        }
        bio_link {
          uid
          url
        }
        bio_image {
          url
          alt
        }
      }
      directors_headline {
      	html
      }
      directors {
        name {
          text
        }
        bio_link {
          uid
          url
        }
        bio_image {
          url
          alt
        }
      } 
      advisory_headline {
      	html
      }
      advisory {
        name {
          text
        }
        bio_link {
          uid
          url
        }
        bio_image {
          url
          alt
        }
      } 
      employees_headline {
      	html
      }
      employees {
        name {
          text
        }
        position {
          text
        }
        bio_link {
          uid
          url
        }
        bio_image {
          url
          alt
        }
      }      
    }
  }
}
`
