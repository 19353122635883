import React from 'react';
import parse from 'html-react-parser';
import * as styles from './Team.module.scss';
import clsx from 'clsx';
import Bio from './Bio';

const Team = ({ headline = '', className, team }) => {

  return (
    <section className={clsx(className, styles.team)}>
      <div className={clsx('section-wrapper', styles.wrapper)}>
        <div className={styles.headline}>
          { parse(headline) }
        </div>
        <div className={styles.list}>
          { team && team.map((bio,i) =>
            <Bio key={i} person={bio} />
          ) }
        </div>
      </div>
    </section>
  )
};

export default Team;