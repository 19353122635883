import React from 'react';
import * as styles from './Bio.module.scss';
import { Link } from 'gatsby';

const Bio = ({ person }) => {

if(person.bio_link.url != null) { 
  return (
    <div className={styles.bio}>
      <Link to={person.bio_link.url}>
        <img className={styles.image} alt={person.bio_image.alt} src={person.bio_image.url} />
      </Link>
      <Link to={person.bio_link.url}>
        <p className={styles.label}>{person.name.text}</p>
      </Link>
      {
        person && person.position &&
        <div className={styles.position}>{ person.position.text }</div>
      }
    </div>
  )} else if(person.bio_link.uid != null) {
  return (
    <div className={styles.bio}>
      <Link to={`/leadership/${person.bio_link.uid}`}>
        <img className={styles.image} alt={person.bio_image.alt} src={person.bio_image.url} />
      </Link>
      <Link to={`/leadership/${person.bio_link.uid}`}>
        <p className={styles.label}>{person.name.text}</p>
      </Link>
      {
        person && person.position &&
        <div className={styles.position}>{ person.position.text }</div>
      }
    </div>
  )} else {
      return (
        <div className={styles.bio}>
            <img className={styles.image} alt={person.bio_image.alt} src={person.bio_image.url} />
            <p className={styles.label}>{person.name.text}</p>
          {
            person && person.position &&
            <div className={styles.position}>{ person.position.text }</div>
          }
        </div>
      )      
  }
};

export default Bio;