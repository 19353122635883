import React from 'react';
import parse from 'html-react-parser';
import clsx from 'clsx';
import * as styles from './InfoBox.module.scss';
import Title from '../Title/Title';

function Stat({ data }) {
    return (
        <div className={clsx(styles.stat, data.css_class.text)}>
            <img src={data.image.url} alt="" />
            <div className={styles.statContent}>
                {data.headline && <p className={styles.statHeadline}>{parse(data.headline.text)}</p>}
                {data.paragraph && <p className={styles.statParagraph}>{parse(data.paragraph.text)}</p>}
                {data.text && <div className={styles.statText}>{parse(data.text.html)}</div>}
            </div>
        </div>
    )
}

/**
 * Possible layouts (css_class): table_stats, small_stats or ''
 */

export default function InfoBox({ data }) {
    const headline = <div className={styles.headline}>
      <Title>{parse(data.headline.html)}</Title>
    </div>;

    return (
        <section className={clsx(styles.infoBox, data.css_class.text)}>
            <div className={clsx('section-wrapper')}>
                <div className={styles.content}>
                    {data.css_class.text !== 'table_stats' && headline}
                    <div className={styles.stats}>
                        {data.css_class.text === 'table_stats' && headline}
                        {data.stats.map((stat,index) => (
                            <Stat key={index} data={stat}/>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}